import axios from 'axios'
import {AuthModel, UserModel, RegisterResponseModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/users/verify-token/`
export const LOGIN_URL = `${API_URL}/users/login/`
export const REGISTER_URL = `${API_URL}/users/register/`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
// Server should return token
export function login(email: string, password: string): Promise<RegisterResponseModel> {
  return axios.post(LOGIN_URL, {
    username: email,
    password,
  }).then(response => response.data) // return both token and user data
}
// Server should return user details

export function register(
  username: string,
  email: string,
  password: string,
  organization: string,
  is_org_admin: boolean,
  first_name: string,
  last_name: string
): Promise<RegisterResponseModel> {
    return axios.post(REGISTER_URL, {
    username,
    email,
    password,
    organization,
    is_org_admin,
    first_name,
    last_name,
  }).then(response => response.data) // return both token and user data
}
// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.get<{token: string, user: UserModel}>(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then(response => response.data.user) // return user data directly
}